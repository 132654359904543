import styles from './Error.module.scss';

import { ERROR } from '@/utilities/constants/Error';
import { useEffect, useState } from 'react';
import { useLocale } from '@/utilities/contexts/Locale';
import { useSegment } from '@/utilities/contexts/segment/SegmentProvider';
import { useWebsite } from '@/utilities/contexts/Website';
import Button from '@shared/components/atoms/Button';
import Column from '@shared/components/atoms/Column';
import Container from '@shared/components/atoms/Container';
import LongArrowLeft from '@shared/icons/maggie/LongArrowLeft';
import Row from '@shared/components/atoms/Row';
import Typography from '@shared/components/atoms/Typography';
import WEBSITE from '@/utilities/constants/Website';

interface ErrorProps {
  code: number;
  description: string;
  title: string;
}

const Error = (props: ErrorProps) => {
  const { code, description, title } = props;

  const [isErrorSet, setIsErrorSet] = useState(false);

  const segment = useSegment();
  const website = useWebsite();
  const locale: string = useLocale();

  useEffect(() => {
    if(code && !isErrorSet) {
      // set it once
      setIsErrorSet(true);
    }
  }, [code])

  useEffect(() => {
    if(isErrorSet && segment) {
      // fire event
      segment?.trackEventWithPayload('safetyculture_web.view_error', {
        path: window.location.pathname,
        reason_for_error: code,
        product_feature: WEBSITE[website].productFeature
      });
    }
  }, [isErrorSet, segment])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <span className={styles.code}>
          <span className={styles.line}></span>
          <span className={styles.number}>{code}</span>
        </span>
        <Container>
          <Row justify="center">
            <Column xs={12} lg={6}>
              <Typography family="poppins" tag="h1" xs={5} lg={6}>
                {title}
              </Typography>
              <Typography tag="p" xs={3}>
                {description}
              </Typography>
              <Button
                before={<LongArrowLeft width={16} height={16} />}
                href="/"
                size="large"
                style="primary"
              >
                {ERROR.back[locale]}
              </Button>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Error;
