import styles from '@/components/molecules/Error/Error.module.scss';

// ... Shared package components
import typeMenus from '@shared/utilities/types/Menus';

import Error from '@/components/molecules/Error';
import Layout from '@/components/pages/Layout';
import propMenus from '@/utilities/props/Menus';
import { ERROR } from '@/utilities/constants/Error';
import { GetStaticProps } from 'next';
import { useLocale } from '@/utilities/contexts/Locale';

interface Error404Props {
  menus: typeMenus;
}

const Error404 = (props: Error404Props) => {
  const { menus } = props;

  const locale: string = useLocale();

  return (
    <Layout
      className={styles.layout}
      menus={{
        header: menus.header,
        legal: menus.legal,
      }}
      seo={{
        openGraph: {
          type: 'website',
        },
        title: ERROR[404].title[locale],
      }}
      customise={{
        header: {
          notification: true,
          navigationStatus: true,
          loginAndSignUpButtonsStatus: true,
        },
        footer: {
          mainMenu: true,
          legalMenu: true,
        },
        breadcrumbsOn: true,
      }}
    >
      <Error
        code={404}
        description={ERROR[404].description[locale]}
        title={ERROR[404].title[locale]}
      />
    </Layout>
  );
};

export default Error404;

export const getStaticProps: GetStaticProps = async (context) => {
  const locale = context.locale as string;

  const menus: { footer: typeMenus; header: typeMenus; legal: typeMenus } =
    (await propMenus({
      locale,
    })) as any;

  return {
    props: {
      menus: menus,
    },
  };
};
