export const ERROR: any = {
  404: {
    description: {
      au: 'The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.',
      de: 'Die gesuchte Seite konnte nicht gefunden werden. Sie wurde möglicherweise entfernt, umbenannt oder hat niemals existiert.',
      en: 'The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.',
      es: 'No fue posible encontrar la página que busca. Puede que haya sido eliminada, cambiado de nombre o que nunca haya existido.',
      fr: "La page que vous recherchez n'a pu être trouvée. Elle a peut-être été supprimée, renommée ou n'existait tout simplement pas.",
      nl: 'De pagina die u zoekt kan niet gevonden worden. Het is mogelijk verwijderd, hernoemd of heeft nooit bestaan.',
      'pt-br':
        'A página que você está procurando não pôde ser encontrada. Ela pode ter sido removida, renomeada ou não existe.',
      us: 'The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.',
    },
    title: {
      au: 'Page not found',
      de: 'Seite nicht gefunden',
      en: 'Page not found',
      es: 'Página no encontrada',
      fr: 'Page non trouvée',
      nl: 'Pagina niet gevonden',
      'pt-br': 'Página não encontrada',
      us: 'Page not found',
    },
  },
  500: {
    description: {
      au: 'The server encountered an internal error or misconfiguration and was unable to complete your request.',
      de: 'Es liegt ein interner Fehler oder eine Fehlkonfiguration vor. Der Server konnte deswegen Ihre Anfrage nicht zu Ende bearbeiten.',
      en: 'The server encountered an internal error or misconfiguration and was unable to complete your request.',
      es: 'El servidor encontró un error interno o una mala configuración y no pudo completar su solicitud.',
      fr: "Le serveur a rencontré une erreur interne ou une mauvaise configuration et n'a pas pu répondre à votre demande.",
      nl: 'De server ondervond een interne fout of verkeerde configuratie en kon uw verzoek niet voltooien.',
      'pt-br':
        'O servidor encontrou um erro interno ou uma configuração incorreta e não foi capaz de concluir o seu pedido.',
      us: 'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    },
    title: {
      au: 'Internal server error',
      de: 'Interner Serverfehler',
      en: 'Internal server error',
      es: 'Error interno del servidor',
      fr: 'Erreur interne au serveur',
      nl: 'Interne serverfout',
      'pt-br': 'Erro interno do servidor',
      us: 'Internal server error',
    },
  },
  back: {
    au: 'Back to home',
    de: 'Zurück zur Startseite',
    en: 'Back to home',
    es: 'Volver al inicio',
    fr: "Retour à l'accueil",
    nl: 'Terug naar de startpagina',
    'pt-br': 'Voltar à página inicial',
    us: 'Back to home',
  },
};
